import React from "react";
import { Suspense, lazy } from "react";
import MainVisualSlider from "@/components/main/main-visual-slider";
import MainNewsPreviewer from "@/components/main/main-news-previewer";
import MainPackageBanner from "@/components/main/main-package-banner";
import MainPromotionSlider from "@/components/main/main-promotion-slider";

const MainRecommendSlider = lazy(() =>
  import("@/components/main/main-recommend-slider"),
);

const Home = () => {
  return (
    <section id="main">
      <MainVisualSlider />

      <Suspense fallback={<div>Loading...</div>}>
        <MainRecommendSlider />
      </Suspense>

      <MainPackageBanner />

      <MainPromotionSlider />

      <MainNewsPreviewer />
    </section>
  );
};

export default Home;
