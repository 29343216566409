import React from "react";
import { Link } from "react-router-dom";

const MainPackageBanner = () => {
  return (
    <div className="package">
      <div className="inner">
        <div className="mainTitle">골프고고 패키지여행</div>
        <ul className="packageList">
          <li>
            <Link to="/philippines/package/0">
              <div className="thum">
                <img src="assets/images/main/packageThum00.png" alt="" />
              </div>
              <div className="bat">NEW</div>
              <div className="textArea">
                <div className="subj">풀빌라 + 매일 18홀 선택 C.C</div>
                <div className="subs">3일 동안 매일 다른 코스 18홀씩 54홀</div>
                <div className="price">600,000원 ~</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/philippines/package/1">
              <div className="thum">
                <img src="assets/images/packageThum01.jpg" alt="" />
              </div>
              <div className="bat">HOT</div>
              <div className="textArea">
                <div className="subj">게스트 하우스 + 54홀 패키지</div>
                <div className="subs">게스트하우스 + 매일 18홀 선택 C.C</div>
                <div className="price">550,000원 ~</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/philippines/package/2">
              <div className="thum">
                <img src="assets/images/packageThum02.jpg" alt="" />
              </div>
              <div className="bat">HOT</div>
              <div className="textArea">
                <div className="subj">호텔 + 54홀 패키지</div>
                <div className="subs">호텔 3박 5일 + 매일 18홀 선택 C.C</div>
                <div className="price">750,000원 ~</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/thailand/package/0">
              <div className="thum">
                <img src="assets/images/packageThum03.jpg" alt="" />
              </div>
              <div className="bat">HOT</div>
              <div className="textArea">
                <div className="subj">파타야 시암 54홀 골프 여행</div>
                <div className="subs">리조트 + 매일 18홀 선택 C.C</div>
                <div className="price">810,000원 ~</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainPackageBanner;
