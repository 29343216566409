import React, { useCallback, useEffect, useState } from "react";
import Parser from "html-react-parser";
import moment from "moment";
import { usePost, usePosts } from "@/api/hooks";
import { Link } from "react-router-dom";

const MainNewsPreviewer = () => {
  const [postData, setPostData] = useState(null);
  /* const noticeList = getNotice().slice(0, 4); */

  //console.log(isLoading, data?.boardList);
  //console.log(data?.boardList.filter((_, index) => index < 5));

  const { isLoading, data } = usePosts();
  let noticeList = data?.boardList?.slice(0, 5);
  let noticeMainData = noticeList?.shift();
  //console.log(noticeList, noticeMainData);

  noticeMainData = usePost(noticeMainData?.board_id);

  const noticeMainData_link = noticeMainData?.data?.data[0]?.board_id;
  const noticeMainData_title = noticeMainData?.data?.data[0]?.title;
  const noticeMainData_content = Parser(
    noticeMainData?.data?.data[0]?.content?.substr(0, 25) + "...",
  );
  const noticeMainData_date = moment(
    noticeMainData?.data?.data[0]?.board_date,
  ).format("YYYY.MM.DD");
  //console.log(noticeMainData.data?.data[0]);
  //console.table(noticeList);

  return (
    <div className="news">
      <div className="titleLine">
        <div className="titleArea">
          <div className="en">GOLFGOGO NEWS</div>
          <div className="title">공지사항</div>
        </div>

        <div className="more">
          <Link to="/notice">
            <div className="text">전체보기</div>
            <div className="ico">
              <img src="assets/images/newsMoreIco.png" alt="" />
            </div>
          </Link>
        </div>
      </div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="newsCont">
          <div className="notice">
            <Link to={`/notice/` + noticeMainData_link}>
              <div className="cont">
                <div className="bat">필독</div>
                <div className="subject">{noticeMainData_title}</div>
                <div className="substance">{noticeMainData_content}</div>
                <div className="date">{noticeMainData_date}</div>
              </div>
              <div className="bg">
                <img src="assets/images/newsThum.jpg" alt="" />
              </div>
            </Link>
          </div>

          <ul className="newsList">
            {noticeList.map((item, index) => (
              <li key={index}>
                <Link to={`/notice/` + item.board_id}>
                  <div className="subject">{item.title}</div>
                  <div className="date">
                    {moment(item.board_date).format("YYYY.MM.DD")}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MainNewsPreviewer;
